const links = [
    {
        label: 'Github',
        url: 'https://github.com/amaralluiz',
    },
    {
        label: 'Twitter',
        url: 'https://twitter.com/amaralluiz_',
    },
    {
        label: 'Youtube',
        url: 'https://www.youtube.com/channel/UCyiDDZaP1qGdMP0aeAXY9fA',
    }
]

export default links